import React from 'react'
import styled from 'styled-components'

import ViberIcon from 'assets/icons/Viber.svg'
import TelegramIcon from 'assets/icons/Telegram.svg'
import WhatsappIcon from 'assets/icons/Whatsapp.svg'
import InstagramIcon from 'assets/icons/Instagram.svg'
import FacebookIcon from 'assets/icons/Facebook.svg'
import { displayWidth } from 'styles/width'
import { sendConversion, sendEvent } from 'tracking'
import { sendForm } from './form/api'
import { colors } from 'styles/colors'

const SocialIconsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: ${displayWidth.desktop}) {
        justify-content: space-between;
    }
`
const ViberIconS = styled(ViberIcon)<{ fill: string }>`
    fill: ${(props) => props.fill};
    margin: 0 5px;
    :hover {
        fill: ${colors.viber};
    }
`
const TelegramIconS = styled(TelegramIcon)<{ fill: string }>`
    fill: ${(props) => props.fill};
    margin: 0 5px;
    :hover {
        fill: ${colors.telegram};
    }
`
const WhatsappIconS = styled(WhatsappIcon)<{ fill: string }>`
    fill: ${(props) => props.fill};
    margin: 0 5px;
    :hover {
        fill: ${colors.whatsapp};
    }
`
const InstagramIconS = styled(InstagramIcon)<{ fill: string }>`
    fill: ${(props) => props.fill};
    margin: 0 5px;
    :hover {
        fill: ${colors.insta};
    }
`
const FacebookIconS = styled(FacebookIcon)<{ fill: string }>`
    fill: ${(props) => props.fill};
    margin: 0 5px;
    :hover {
        fill: ${colors.facebook};
    }
`
interface ISocialIconsProps {
    fill: string
    showAllIcons?: Boolean
    placement: string
}

export const SocialIcons: React.FC<ISocialIconsProps> = ({
    fill,
    showAllIcons,
    placement,
}) => {
    return (
        <SocialIconsWrapper>
            <a
                href="viber://pa?chatURI=clearline_com_ua"
                target="blank"
                onClick={() => {
                    sendForm(`ViberIcon${placement}`, {})
                    sendConversion('ViberClick')
                    sendEvent('Click', {
                        eventCategory: 'viber',
                        placement,
                    })
                }}
                id="viberClickButton"
            >
                <ViberIconS aria-label="ViberButton" fill={fill} />
            </a>
            <a
                href="tg://resolve?domain=ClearLine_bot"
                target="blank"
                onClick={() => {
                    sendForm(`TelegramIcon${placement}`, {})
                    sendConversion('telegram')
                    sendEvent('Click', {
                        eventCategory: 'telegram',
                        placement,
                    })
                }}
                id="telegramClickButton"
            >
                <TelegramIconS aria-label="Telegram Button" fill={fill} />
            </a>
            <a
                href="https://wa.me/+380958363420"
                target="blank"
                onClick={() => {
                    sendForm(`WhatsappIcon${placement}`, {})
                    sendConversion('whatsapp')
                    sendEvent('Click', {
                        eventCategory: 'whatsapp',
                        placement,
                    })
                }}
                id="whatsAppClickButton"
            >
                <WhatsappIconS aria-label="Whatsapp Button" fill={fill} />
            </a>
            {showAllIcons && (
                <>
                    <a
                        href="https://ig.me/m/clear_line/"
                        target="blank"
                        onClick={() => {
                            sendForm(`InstagramIcon${placement}`, {})
                            sendConversion('SocialIconInstagram')
                            sendEvent('Click', {
                                eventCategory: 'SocialIconInstagram',
                                placement,
                            })
                        }}
                        id="instagramClickButton"
                    >
                        <InstagramIconS
                            aria-label="Instagram Button"
                            fill={fill}
                        />
                    </a>
                    <a
                        href="https://m.me/clearline.com.ua/"
                        target="blank"
                        onClick={() => {
                            sendForm(`FacebookIcon${placement}`, {})
                            sendConversion('SocialIconFacebook')
                            sendEvent('Click', {
                                eventCategory: 'SocialIconFacebook',
                                placement,
                            })
                        }}
                        id="facebookClickButton"
                    >
                        <FacebookIconS
                            aria-label="Facebook Button"
                            fill={fill}
                        />
                    </a>
                </>
            )}
        </SocialIconsWrapper>
    )
}
