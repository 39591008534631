import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { headerHeight } from 'styles/height'
import { displayWidth } from 'styles/width'
import { sendConversion, sendEvent } from 'tracking'
import { contactInformation } from './contactInformation'
import { sendForm } from './form/api'
import { PhoneLink } from './PhoneLink'
import { LanguageSwitcher } from 'i18n/LanguageSwitcher'
import ExitSvg from 'assets/icons/Exit.svg'
import BurgerSvg from 'assets/icons/Burger.svg'
import { Logo } from 'components/Logo'
import { MainMenu } from 'blocks/Header/MainMenu'
import { Icon } from 'components/Icon'

import { RoundText } from 'components/RoundText'
import { PhoneSvgAnimated } from 'components/PhoneSvgAnimated'
import { useTranslation } from 'react-i18next'

const MobileHeaderWraper = styled.div<{ isMenuOpen: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 10;
    background-color: ${({ isMenuOpen }) =>
        isMenuOpen ? colors.dark : '#ebebeb'};

    border-bottom: 1px solid
        ${({ isMenuOpen }) => (isMenuOpen ? colors.white : colors.dark)};
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const Phone = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    height: inherit;
    color: ${colors.dark};
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    margin-right: 20px;
    white-space: nowrap;
`

const MobileMenu = styled.div<{ isMenuOpen: boolean }>`
    position: fixed;
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    top: 66px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const BurgerButton = styled.span`
    width: ${headerHeight.mobile};
    height: ${headerHeight.mobile};
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
`

const PhoneLinkStyled = styled(PhoneLink)`
    display: flex;
    flex-direction: column;
    color: ${colors.white};
    margin-bottom: 80px;
    font-weight: normal;
    svg {
        fill: ${colors.white};
    }
`
const PhoneSvgNew = styled(Icon)`
    width: 25px;
    height: 25px;

    fill: white;
    animation-name: jump;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @keyframes jump {
        0% {
            transform: rotate(0deg);
        }
        5% {
            transform: rotate(-30deg);
        }
        10% {
            transform: rotate(0deg);
        }
        15% {
            transform: rotate(30deg);
        }
        20% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(15deg);
        }
        30% {
            transform: rotate(0deg);
        }
        35% {
            transform: rotate(-15deg);
        }
        40% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
`
const PhoneSvgWrap = styled.div`
    height: 45px;
    width: 45px;
    padding: 10px;
    border-radius: 30px;
    margin: 0 15px;
    box-sizing: border-box;
    background-color: #000;
`
export const MobileMenuPosadka = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { t } = useTranslation()
    return (
        <>
            <MobileHeaderWraper isMenuOpen={isMenuOpen}>
                <Logo />
                <BurgerButton
                    onClick={() => {
                        setIsMenuOpen(!isMenuOpen)
                    }}
                >
                    <BurgerSvg fill={colors.dark} />
                </BurgerButton>

                <Phone
                    href={'tel:+380982117690'}
                    onClick={() => {
                        sendForm(`PosadkaHeaderPhoneClick`, {})
                        sendConversion('Phone')
                        sendEvent('Click', {
                            eventCategory: 'Phone',
                            placement: 'PosadkaHeader',
                        })
                    }}
                    id="phoneClickButton"
                >
                    {' '}
                    +380982117690{' '}
                    <PhoneSvgWrap>
                        <PhoneSvgNew iconName={'Phone.svg'} />
                    </PhoneSvgWrap>
                </Phone>
            </MobileHeaderWraper>
            <MobileMenu isMenuOpen={isMenuOpen}>
                <MobileHeaderWraper isMenuOpen={isMenuOpen}>
                    <LanguageSwitcher closeMenu={setIsMenuOpen} />
                    <BurgerButton
                        onClick={() => {
                            setIsMenuOpen(!isMenuOpen)
                        }}
                    >
                        <ExitSvg fill={colors.white} />
                    </BurgerButton>
                </MobileHeaderWraper>

                <MainMenu onMenuItemClick={() => setIsMenuOpen(false)} />
                <PhoneLinkStyled
                    phone={contactInformation.primaryPhone}
                    placement={'MobileMenuPosadka'}
                >
                    <RoundText color={colors.white} text={t('callUs')}>
                        <PhoneSvgAnimated color={colors.white} />
                    </RoundText>
                </PhoneLinkStyled>
            </MobileMenu>
        </>
    )
}
