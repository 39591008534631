import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { sendConversion, sendEvent } from 'tracking'
import { Button } from './Button'
import { sendForm } from './form/api'
import Viber from 'assets/icons/Viber.svg'
import Telegram from 'assets/icons/Telegram.svg'
import Whatsapp from 'assets/icons/Whatsapp.svg'
import i18n from 'i18n/config'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { Form, IChildrenProps } from './form/Form'
import { PhoneInput } from './form/PhoneInput'
import { Modal } from './Modal'
import { useTranslation } from 'react-i18next'
import { useFormHandler } from 'hooks/useFormHandler'
import { Title } from './TitleComponent'
import { graphql, useStaticQuery } from 'gatsby'
import { EmailInput } from './form/EmailInput'
import { MessageInput } from './form/MessageInput'

const CallbackButton = styled(Button)`
    width: 250px;
    margin: 10px;
    z-index: 3;
    background-color: transparent;
    color: ${colors.darkText};
    border-width: 2px;
    height: 40px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 20px;
    letter-spacing: 1.4px;
    :hover {
        background-color: #000;
        color: #fff;
    }
    @media (max-width: 350px) {
        width: 250px;
        line-height: 11px;
        margin-left: 19px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        width: 220px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        width: 264px;
    }
`
const iconStyles = css`
    width: 38px;
    height: 38px;
    margin: 4px 10px;
    cursor: pointer;
    pointer-events: auto;
`
const ViberIconStyled = styled(Viber)`
    ${iconStyles};
    fill: ${colors.viber};
`
const TelegramIconStyled = styled(Telegram)`
    ${iconStyles};
    fill: ${colors.telegram};
`
const WhatsappIconStyled = styled(Whatsapp)`
    ${iconStyles};
    fill: ${colors.whatsapp};
`
const IconWrapper = styled.div`
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: flex-end;
    padding: 0 13px;
    box-sizing: border-box;
`
const BottomIcons = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    z-index: 10;
    background-color: ${backgroundColors.formPromo};
    display: flex;
    align-items: center;
    border-top: 1px solid #000;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media (orientation: landscape) {
        padding: 10px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        align-items: flex-start;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 30px 70px 0 30px;
        justify-content: space-around;
    }
`
const InputBlock = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${displayWidth.desktop}) {
        width: calc(100% - 150px);
        margin-right: 150px;
    }
`
const TitleStyled = styled(Title)`
    margin: 50px 0;
    @media (orientation: landscape) {
        margin: 16px 0;
    }
`

export const PosadkaLinkBlock = () => {
    const [isModalOpen, setModalIsOpen] = useState(false)
    const { t } = useTranslation()

    const data = useStaticQuery(graphql`
        query {
            allCallbackButtonYaml {
                edges {
                    node {
                        subTitle
                        title
                        contactUs
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)
    const addressData = getDataByLanguage(
        data.allCallbackButtonYaml,
        i18n.language
    )
    const {
        handleSubmitStatus,
        handleFormSendStart,
        formSendStatus,
    } = useFormHandler()
    const { contactUs } = addressData
    return (
        <>
            <Modal
                isOpen={isModalOpen}
                closeHandler={() => setModalIsOpen(false)}
            >
                <Wrapper>
                    <TitleStyled>{contactUs}</TitleStyled>
                    <Form
                        formName={'Question Form'}
                        buttonText={t('send')}
                        onFormSubmit={handleSubmitStatus}
                        onFormSendStart={handleFormSendStart}
                        formSendStatus={formSendStatus}
                        closeHandler={setModalIsOpen}
                        conversionType={'CallBackButtonFormPosadka'}
                        eventCategory={'Callback'}
                    >
                        {({ register, errors }: IChildrenProps) => (
                            <InputBlock>
                                <PhoneInput
                                    ref={register({
                                        minLength: 18,
                                        required: true,
                                    })}
                                    err={errors.phone}
                                />
                                <MessageInput
                                    ref={register}
                                    err={errors.message}
                                />
                                <EmailInput ref={register} err={errors.email} />
                            </InputBlock>
                        )}
                    </Form>
                </Wrapper>
            </Modal>
            <BottomIcons>
                <IconWrapper>
                    <CallbackButton
                        onClick={() => {
                            setModalIsOpen(true)
                            sendForm(`ContactUsBottom`, {})
                            sendConversion('ContactUs')
                            sendEvent('Click', {
                                eventCategory: 'contactUs',
                                placement: 'PosadkaMobile',
                            })
                        }}
                        dangerouslySetInnerHTML={{
                            __html: contactUs,
                        }}
                        id="writeUsButton"
                    />
                    <a
                        href="viber://pa?chatURI=clearline_com_ua"
                        target="blank"
                        onClick={() => {
                            sendForm(`ViberIconPosadkaMobileZa99`, {})
                            sendConversion('ViberClick')
                            sendEvent('Click', {
                                eventCategory: 'viber',
                                placement: 'PosadkaMobile',
                            })
                        }}
                        id="viberClickButton"
                    >
                        <ViberIconStyled aria-label="ViberButton" />
                    </a>
                    <a
                        href="https://wa.me/+380958363420"
                        target="blank"
                        onClick={() => {
                            sendForm(`WhatsAppIconPosadkaMobileZa99`, {})
                            sendConversion('whatsapp')
                            sendEvent('Click', {
                                eventCategory: 'whatsapp',
                                placement: 'PosadkaMobile',
                            })
                        }}
                        id="whatsAppClickButton"
                    >
                        <WhatsappIconStyled aria-label="Whatsapp Button" />
                    </a>
                    <a
                        href="tg://resolve?domain=ClearLine_bot"
                        target="blank"
                        id="telegramClickButton"
                    >
                        <TelegramIconStyled
                            aria-label="Telegram Button"
                            onClick={() => {
                                sendForm(`TelegramIconPosadkaMobileZa99`, {})
                                sendConversion('telegram')
                                sendEvent('Click', {
                                    eventCategory: 'telegram',
                                    placement: 'PosadkaMobile',
                                })
                            }}
                        />
                    </a>
                </IconWrapper>
            </BottomIcons>
        </>
    )
}
